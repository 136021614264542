import React from 'react';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import fragments from '../utils/graphql/fragments';

import LocationSearch from './location/LocationSearch';
import LocationContent from './location/LocationContent';
import Loading from '../utils/Loading';
import StaticAlert from '../utils/StaticAlert';

////////// COMPONENT //////////
export default function Locations(props) {
  return (<>
    <Query query={GET_LOCATIONS} >
      {({ loading, error, data }) => {
        if (loading) return <Loading />
        if (error) {
          console.log("Failed to load list of locations:", error);
          return <StaticAlert type="error" message="Failed to load list of locations." />
        };
        if (data) return <LocationSearch locations={data.locations} />
        else return <StaticAlert type="warning" message="List of locations not found." />
      }}
    </Query>
    {window.location.pathname !== `/locations` ?
      <Query query={GET_LOCATION} variables={{ id: props.match.params.id }}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) {
            console.log("Failed to load result location:", error);
            return <StaticAlert type="error" message="Failed to load result location." />
          }
          if (data.locations[0]) return <LocationContent loc={data.locations[0]} />
          else return <StaticAlert type="warning" message="Result location not found." />
        }}
      </Query > : null
    }
  </>)
}

////////// GRAPHQL //////////
const GET_LOCATIONS = gql`
  query {
    locations(
      order_by: {
        name: asc
      }
      where: {
        active: {
          _eq: 1
        }
        type: {
          _eq: "customer"
        }
        name: {
          _is_null: false
        }
        address: {
          _is_null: false
        }
      }
    ) {
      id
      name
    }
  }
`;

const GET_LOCATION = gql`
  query getLocation($id: bigint!) {
    locations(
      where: {
        id: {
          _eq: $id
        }
        type: {
          _eq: "customer"
        }
      }
    ) {
      ...Location
    }
  }
  ${fragments.location}
`;