import React, { useContext } from 'react';
import GlobalContext from '../../../GlobalContext';
import { makeStyles, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Icon } from '@material-ui/core';

////////// COMPONENT //////////
export default function FaqSearch(props) {
  const { theme } = useContext(GlobalContext);
  const cls = useStyles();
  const { faq, expanded, handleExpanded } = props;

  return (
    <ExpansionPanel className={cls.root} style={{ borderRadius: expanded === faq.id && theme.shape.borderRadius }} expanded={expanded === faq.id} onChange={() => handleExpanded(faq.id)}>
      <ExpansionPanelSummary
        expandIcon={<Icon>expand_more</Icon>}
        aria-controls={`panel-${faq.id}-content`}
        id={`panel-${faq.id}-header`}
        className={cls.questionBox}>
        <Typography className={cls.question}>{faq.question}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={cls.answerBox}>
        <Typography className={cls.answerHead}>Answer:</Typography>
        <Typography className={cls.answer}>{faq.answer}</Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    border: theme.border,
    boxShadow: 'none',
  },
  questionBox: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  question: {
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  answerBox: {
    display: 'block',
    borderRadius: `0 0 ${theme.shape.borderRadius} ${theme.shape.borderRadius}`,
    padding: theme.spacing(2),
    background: theme.palette.background.dark,
  },
  answerHead: {
    marginBottom: theme.spacing(1),
    fontSize: 16,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  answer: {
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
}));