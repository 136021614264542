import React, { useContext } from 'react';
import moment from 'moment';
import { makeStyles, Grid, Typography, Tooltip } from '@material-ui/core';
import GlobalContext from '../../../GlobalContext';

const placeholder = require('../../../static/vehicle-placeholder.jpg');

const capFirst = (str) => { if (str) return str.charAt(0).toUpperCase() + str.slice(1); }

////////// COMPONENT //////////
export default function MoveDetailsInfo(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();
  const { move, tookan } = props;

  const getVehicle = () => {
    let vehicle = ``;
    if (!move.vehicle_make && !move.vehicle_model) vehicle = `No Vehicle Info`;
    else vehicle = `${move.vehicle_year} ${move.vehicle_make} ${move.vehicle_model}`;

    let vehicleColor = ``;
    if (move.vehicle_color) vehicleColor = ` (${capFirst(move.vehicle_color)})`;

    return vehicle + vehicleColor;
  }

  const getDriverInitials = () => {
    let fullName = move.driver_name ? move.driver_name : `N/A`
    if (fullName !== `N/A`) {
      let names = fullName.split(` `);
      let firstI = names[0][0];
      let secondI = names[names.length - 1][0];
      return `${firstI}${secondI}`.toUpperCase();
    }
    else return fullName;
  }

  const getStatus = () => {
    if (move.cancel_status && move.cancel_status !== ``) {
      if (move.cancel_status === `canceled`) return `Cancelled`;
      else if (move.cancel_status === `pending`) return `Cancel Pending`;
      else if (move.cancel_status === `seen`) return `Cancel Seen`;
      else if (move.cancel_status === `started`) return `Cancel Started`;
      else return move.cancel_status;
    }
    else if (move.status && move.status !== ``) {
      if (move.status === `dispatched`) return `Dispatched`;
      else if (move.status === `pickup started`) return `Pickup Started`;
      else if (move.status === `pickup arrived`) return `Pickup Arrived`;
      else if (move.status === `pickup successful`) return `Pickup Successful`;
      else if (move.status === `delivery started`) return `Delivery Started`;
      else if (move.status === `delivery arrived`) return `Delivery Arrived`;
      else if (move.status === `delivery successful`) return `Delivery Successful`;
      else if (move.status === `droppedOff`) return `Dropped Off`;
      else if (move.status === `canceled`) return `Cancelled`;
      else if (move.status === `failed`) return `Failed`;
      else return move.status;
    }
    else return `Unplanned`;
  }

  function isConsumer() {
    if ((move.consumer_name || move.consumer_phone) && ['delivery successful', 'canceled', 'cancelled'].indexOf(move.status) >= 0) return true;
    else return false;
  }

  return (<>
    <div className={cls.main}>
      <div className={cls.info}>
        <Typography className={cls.infoTxt}>VEHICLE</Typography>
        <div className={cls.smallBreak} />
        <Tooltip title={getVehicle()}>
          {tookan && tookan.pickupImage ?
            <img className={cls.infoImage} src={tookan.pickupImage} alt="vehicle" /> :
            <img className={cls.infoImage} src={placeholder} alt="placeholder vehicle" />
          }
        </Tooltip>
      </div>

      {ctx.width === `sm` || ctx.width === `xs` ? <div className={cls.bigBreak} /> : null}

      <div className={cls.paper}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography className={cls.headTxt} style={{ textAlign: 'left' }}>Information</Typography>
          </Grid>
          <Grid item xs={4}>
            <Tooltip placement="top" title="Move Date">
              <Typography className={cls.headTxt} style={{ textAlign: 'center' }}>{moment(move.pickup_started ? move.pickup_started : move.pickup_time).format(`MM/DD/YYYY`)}</Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            <Tooltip placement="top-end" title="Move ID">
              <Typography className={cls.headTxt} style={{ textAlign: 'right' }}>#{move.id}</Typography>
            </Tooltip>
          </Grid>
        </Grid>

        <div className={cls.break} />

        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Vehicle:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{!isConsumer() ? getVehicle() : "Info expired"}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Transmission:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{!isConsumer() ? move.manual_flag ? `Manual` : `Automatic` : "Info expired"}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Stock:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{!isConsumer() ? move.vehicle_stock ? move.vehicle_stock : `-` : "Info expired"}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>VIN:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{!isConsumer() ? move.vehicle_vin ? move.vehicle_vin : `-` : "Info expired"}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Reference&nbsp;#:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{!isConsumer() ? move.reference_num ? move.reference_num : `-` : "Info expired"}</Typography>
            </div>

          </Grid>

          <div className={cls.hiddenLineBreak} />

          <Grid item sm={6} xs={12}>
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Driver&nbsp;Name:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{move.driver_name ? move.driver_name : `SAT Driver`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Dealer:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{move.customer.name ? move.customer.name : `Unknown Dealer`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Move&nbsp;Status:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{getStatus()}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Drive&nbsp;Distance:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{move.lane.distance_miles ? `${move.lane.distance_miles} miles` : `-`}</Typography>
            </div>
            <div className={cls.lineBreak} />
            <div className={cls.listItem}>
              <Typography className={cls.keyTxt}>Notes/Instructions:&nbsp;&nbsp;</Typography>
              <Typography className={cls.valTxt}>{move.move_details ? move.move_details : `-`}</Typography>
            </div>
          </Grid>
        </Grid>
      </div>

      {ctx.width === `sm` || ctx.width === `xs` ? <div className={cls.bigBreak} /> : null}

      <div className={cls.info}>
        <Typography className={cls.infoTxt}>DRIVER</Typography>
        <div className={cls.smallBreak} />
        <Tooltip title={move.driver_name ? move.driver_name : `SAT Driver`}>
          {tookan && tookan.driver && tookan.driver.fleet_thumb_image ?
            <img className={cls.infoImage} src={tookan.driver.fleet_thumb_image} alt="driver" /> :
            <div className={cls.infoImage}>
              <Typography className={cls.infoNoDriverTxt}>{getDriverInitials()}</Typography>
            </div>
          }
        </Tooltip>
      </div>
    </div>

    {move.consumer_name || move.consumer_phone ? <>
      <div className={cls.bigBreak} />

      <div className={cls.main}>
        <div className={cls.info}>
          <div className={cls.infoSpacer} />
        </div>

        <div className={cls.paper}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Consumer&nbsp;Name:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{!isConsumer() ? move.consumer_name ? move.consumer_name : `-` : "Info Expired"}</Typography>
              </div>
            </Grid>

            <div className={cls.hiddenLineBreak} />

            <Grid item sm={6} xs={12}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Consumer&nbsp;Phone:&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{!isConsumer() ? move.consumer_phone ? move.consumer_phone : `-` : "Info Expired"}</Typography>
              </div>
            </Grid>
          </Grid>

        </div>

        <div className={cls.info}>
          <div className={cls.infoSpacer} />
        </div>
      </div>
    </> : null}
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  info: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  infoTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  infoImage: {
    objectFit: 'cover',
    display: 'block',
    position: 'relative',
    width: '128px',
    height: '128px',
    border: `1px solid #cdd6e5`,
    borderRadius: '50%',
    background: `#cdd6e5`,
    verticalAlign: 'top',
  },
  infoSpacer: {
    display: 'block',
    position: 'relative',
    width: '128px',
  },
  infoNoVehicleTxt: {
    position: 'absolute',
    top: '42%',
    left: 0,
    right: 0,
    color: '#fff',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 700,
  },
  infoNoDriverTxt: {
    position: 'absolute',
    top: '21%',
    left: 0,
    right: 0,
    color: '#fff',
    textAlign: 'center',
    fontSize: '48px',
    fontWeight: 700,
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    background: '#fff',
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    cursor: 'default',
  },
  list: {

  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.divider,
  },
  hiddenLineBreak: {
    display: 'none',
    width: '100%',
    height: '1px',
    marginTop: '-2px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: theme.palette.divider,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));