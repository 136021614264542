import React, { useState } from 'react';
import { makeStyles, Container, TextField } from '@material-ui/core';

import Divide from '../../reusable/Divide';

////////// COMPONENT //////////
export default function FaqSearch(props) {
  const cls = useStyles();

  const [input, setInput] = useState(``);

  const handleInput = (event) => {
    setInput(event.target.value);
  }

  return (
    <div className={cls.backdrop}>
      <Container maxWidth="md">
        <Divide color="white" smallSpacer>Filter</Divide>
        <div className={cls.hold}>
          <TextField
            fullWidth
            name="time"
            label=""
            placeholder="Filter by question..."
            variant="outlined"
            margin="dense"
            value={input}
            onChange={handleInput}
            style={{ margin: 0 }}
          />
        </div>
      </Container>
    </div>
  )
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  backdrop: {
    position: 'relative',
    width: '100%',
    paddingBottom: theme.spacing(2),
    backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
  },
  hold: {
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
  },
}));