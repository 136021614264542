import gql from 'graphql-tag';

// ------------------------------------------------- Location Object ----------------------------------------------
const locationFragment = () => gql`
  fragment Location on locations {
    id
    active
    address
    customer_id
    customer {
      ...Customer
    }
    email
    latitude
    longitude
    name
    phone
    notes
    place_id
    region_id
    type
  }
  ${customerFragment()}
`;
// ------------------------------------------------- Customer Object ----------------------------------------------
const customerFragment = () => gql`
  fragment Customer on customers {
    name
  }
`;
// ------------------------------------------------- Lane Object --------------------------------------------------
const laneFragment = () => gql`
  fragment Lane on lanes {
    id
    active
    customer_id
      description
      pickup {
        ...Location
      }
      delivery {
        ...Location
      }
    average_drive_speed_min_per_mile
    average_drive_speed_mph
    delivery_inspection_sec
    destination_location_id
    distance_miles
    driver_base_pay_discount
    driver_pay_per_kilometer
    driver_pay_per_mile
    driver_pay_per_minute
    driver_rake
    driver_return_pay_discount
    driver_time_pay
    duration_sec
    origin_location_id
    pickup_inspection_sec
    return_ride_wait_sec
    tolls
  }
  ${customerFragment()}
  ${locationFragment()}
`;

const appaymentFragment = () => gql`
  fragment APPayment on appayments {
    active
    id
    driver_id
    move_id
    status
    ach_transaction_id
    amount
    createdat
    updatedat
    notes
    author
    type
    accessorial_id
  }
`;
const accessorialsFragment = () => gql`
  fragment Accessorials on accessorials {
    id
    move_id
    code
    status
    notes
    cost
    ap_amount
    ar_amount
  }
`;

const fragments = {
  location: locationFragment(),
  customer: customerFragment(),
  lane: laneFragment(),
  accessorials: accessorialsFragment(),
  appayment: appaymentFragment(),
};

export default fragments;