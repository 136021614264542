import GlobalContext from "../../GlobalContext";
import { ApolloProvider } from "react-apollo";
import gql from "graphql-tag";
import React, { useContext, useState, useEffect } from "react";
import { Query } from "react-apollo";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import moment from "moment";

import PageRoot from "../reusable/PageRoot";

import Loading from "../utils/Loading";

import DriverScheduleBody from "./schedule/ScheduleBody";
import DriverScheduleHeader from "./schedule/ScheduleHeader";

let log = false;

export default function Schedule(props) {
  const ctx = useContext(GlobalContext);
  ctx.handlePage();
  const cls = useStyles();

  // const driverId = ctx.userAuth && ctx.userAuth["https://hasura.io/jwt/claims"]['x-hasura-driver-id'];

  const [tabValue, setTabValue] = useState(moment().format("W") % 2);
  const [driverId, setDriverId] = useState(null);

  useEffect(() => {
    ctx.userAuth && setDriverId(ctx.userAuth["https://hasura.io/jwt/claims"]["x-hasura-driver-id"]);
  }, [driverId, ctx.userAuth]);

  return (
    <PageRoot>
      <Container maxWidth="md">
        <ApolloProvider client={ctx.apolloClient}>
          {/* <CheckJWT /> */}
          <DriverScheduleHeader tabValue={tabValue} setTabValue={setTabValue} />
          <div style={{ width: "100%", height: "24px" }} />
          {driverId && typeof tabValue === "number" && ctx && ctx.userAuth ? (
            <Query
              query={GET_SCHEDULES}
              variables={{ driver_id: driverId, type: tabValue ? "odd" : "even" }}
              fetchPolicy="no-cache"
            >
              {({ ...result }) => {
                if (result.loading) return <Loading />;
                if (result.error) return `Error! ${result.error.message}`;
                log && console.log(driverId ? driverId : null, result.data.driverschedules);
                return (
                  <>
                    <DriverScheduleBody
                      // schedules={Array.from(result.data.driverschedules || [])}
                      schedule={result.data.driverschedules.length > 0 ? result.data.driverschedules[0] : null}
                      driverId={driverId}
                      selectedWeek={tabValue === 1 ? "odd" : "even"}
                    />
                  </>
                );
              }}
            </Query>
          ) : (
            <Container maxWidth="sm">
              <div className={cls.notFound}>
                <Typography className={cls.notFoundTxt}>Schedule not found</Typography>
              </div>
            </Container>
          )}
        </ApolloProvider>
      </Container>
    </PageRoot>
  );
  // }
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  dropdown: {
    minWidth: 200,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  notFound: {
    padding: theme.spacing(4),
    border: theme.border,
    borderRadius: theme.shape.borderRadius,
    marginLeft: "auto",
    marginRight: "auto",
    background: theme.palette.background.paper,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: "center",
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
}));

const GET_SCHEDULES = gql`
  query get_schedules($driver_id: Int, $type: String) {
    driverschedules(where: { driver_id: { _eq: $driver_id }, type: { _eq: $type } }, order_by: { id: asc }) {
      id
      driver_id
      type
      expiration_date
      details {
        id
        driver_schedule_id
        day
        block_start_time
        block_end_time
      }
    }
  }
`;
