import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { makeStyles, Container, Typography, Button } from '@material-ui/core';
import GoogleStaticMapURL from '../../utils/GoogleStaticMapUrl';
import helpers from '../../utils/helpers';
import axios from 'axios';

import PageRoot from '../../reusable/PageRoot';
import Divide from '../../reusable/Divide';

////////// COMPONENT //////////
function LaneDetailsContent(props) {
  const cls = useStyles();
  const [lanePay, setLanePay] = useState({ drivePay: "0.00", ridePay: "0.00" })
  const { lane } = props;

  useEffect(() => {
    async function fetchLanePay() {
      let lanePay;
      try {
        await axios({
          method: 'POST',
          url: '/.netlify/functions/handleDriverPayCalc',
          data: {
            lane: lane,
            type: "drive"
          }
        }).then(res => lanePay = res.data)
      } catch (err) {
        console.log("Failed to fetch lane pay")
        lanePay = { drivePay: "0.00", ridePay: "0.00" }
      }
      setLanePay(lanePay);
    }
    fetchLanePay()
  }, [lane])

  const staticMapUrl = (lane) => GoogleStaticMapURL.build({
    key: process.env.REACT_APP_GOOGLE_STATIC_MAPS_KEY,
    markers: [
      {
        icon: 'https://s3.ap-south-1.amazonaws.com/social-auto/acknowledgement_images/fkpH1532077213498-faviconsavein32x32.png',
        shadow: true,
        lat: lane.pickup.latitude,
        lon: lane.pickup.longitude,
      },
      {
        icon: 'https://s3.ap-south-1.amazonaws.com/social-auto/acknowledgement_images/fkpH1532077213498-faviconsavein32x32.png',
        shadow: true,
        lat: lane.delivery.latitude,
        lon: lane.delivery.longitude,
      },
    ],
    center: 'auto',
    zoom: 'auto',
    scale: false,
    size: '720x360',
    maptype: 'roadmap',
    format: 'png',
    visual_refresh: true
  });

  return (<>
    <PageRoot>
      <Container maxWidth="md">
        <Divide>Map</Divide>

        <div className={cls.paper}>
          <img className={cls.map} src={staticMapUrl(props.lane)} alt={`lane-img`} />
          <div className={cls.btnHold}>
            <a href={`https://maps.google.com?saddr=${props.lane.pickup.address}&daddr=${props.lane.delivery.address}`} target="_blank" rel="noopener noreferrer">
              <Button variant="contained" color="primary" className={cls.btn}>Open in Google</Button>
            </a>
          </div>
        </div>

        <Divide spacer>From</Divide>

        <div className={cls.paper}>
          <Typography className={cls.subtxt}>{props.lane.pickup.name}</Typography>
          <Typography className={cls.txt}>{helpers.handleCommaWhitespace(props.lane.pickup.address)}</Typography>
          <div style={{ width: '100%', height: '8px' }} />
          <a className={cls.link} href={`https://maps.google.com/?q=${props.lane.pickup.address}`} target="_blank" rel="noopener noreferrer">
            <Button color="primary">Open in Google</Button>
          </a>
        </div>

        <Divide spacer>To</Divide>

        <div className={cls.paper}>
          <Typography className={cls.subtxt}>{props.lane.delivery.name}</Typography>
          <Typography className={cls.txt}>{helpers.handleCommaWhitespace(props.lane.delivery.address)}</Typography>
          <div style={{ width: '100%', height: '8px' }} />
          <a className={cls.link} href={`https://maps.google.com/?q=${props.lane.delivery.address}`} target="_blank" rel="noopener noreferrer">
            <Button color="primary">Open in Google</Button>
          </a>
        </div>

        <Divide spacer>Details</Divide>

        <div className={cls.paper}>
          <Typography className={cls.subtxt} style={{ marginBottom: '2px' }}>Drive Pay: ${lanePay.drivePay}</Typography>
          <Typography className={cls.subtxt}>Ride Pay: ${lanePay.ridePay}</Typography>
          <Typography className={cls.txt}>Distance: {props.lane.distance_miles} mi</Typography>
          <Typography className={cls.txt}>Time Estimate: {(props.lane.duration_sec / 60).toFixed(0)} min</Typography>
          <Typography className={cls.txt}>Tolls: {props.lane.tolls ? `$${props.lane.tolls.tofixed(2)}` : 'N/A'}</Typography>
        </div>
      </Container>
    </PageRoot>
  </>)
}
export default withRouter(LaneDetailsContent);

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    border: theme.border,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
  },
  paperLink: {
    padding: theme.spacing(2),
    border: theme.border,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      background: theme.palette.background.dark,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  map: {
    display: 'block',
    width: '100%',
    maxWidth: '720px',
    border: theme.border,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  subtxt: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: 18,
    fontWeight: 700,
  },
  txt: {
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  btnHold: {
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  btn: {
    boxShadow: 'none',
    "&:hover": {
      background: theme.palette.primary.light,
      boxShadow: 'none',
    }
  },
}));