import React, { useContext } from "react";
import moment from "moment";
import GlobalContext from "../../GlobalContext";
import { makeStyles } from "@material-ui/core";

import gql from "graphql-tag";
import { Query } from "react-apollo";

import Loading from "../utils/Loading";
import StaticAlert from "../utils/StaticAlert";
import DashboardContent from "./dashboard/DashboardContent";

const log = false;

////////// COMPONENT //////////
export default function Dashboard() {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const driverId = ctx.userAuth && ctx.userAuth["https://hasura.io/jwt/claims"]["x-hasura-driver-id"];
  const driverName = ctx.userAuth && ctx.userAuth.nickname;
  const driverPic = ctx.userAuth && ctx.userAuth.picture;

  return (
    <>
      <div className={cls.backdrop} />
      {ctx && ctx.userAuth && (
        <Query query={GET_DASHBOARD_INFO}>
          {({ loading, error, data }) => {
            if (loading) return <Loading />;
            if (error) {
              console.log("Failed to load dashboard info:", error);
              return <StaticAlert type="error" message="Failed to load dashboard info." />;
            }
            if (data) {
              const timer = Date.now();
              const getInitials = (fullName) => {
                let names = fullName.split(` `);
                let firstI = names[0][0];
                let secondI = names[names.length - 1][0];
                return `${firstI}${secondI}`.toUpperCase();
              };

              const getPayments = (payments, start, end) => {
                return payments.filter((p) => moment(p.move.pickup_time) >= start && moment(p.move.pickup_time) <= end);
              };
              const getPay = (payments) => {
                const pay =
                  payments.length > 0
                    ? payments
                        .map((p) => p.amount)
                        .reduce((total, currentValue) => total + currentValue)
                        .toFixed(2)
                    : `0.00`;
                return `$${pay}`;
              };
              const getMoves = (payments) => {
                let moves = [];
                if (payments.length > 0) {
                  payments.forEach((p) => {
                    if (!moves.includes(p.move.id)) {
                      moves.push(p.move.id);
                    }
                  });
                }
                return moves.length;
              };
              const getTime = (payments) => {
                const ms =
                  payments.length > 0
                    ? parseInt(
                        payments
                          .map((p) => p.move.lane.duration_sec * 1000)
                          .reduce((total, currentValue) => total + currentValue)
                          .toFixed(0)
                      )
                    : 0;
                return moment.utc(moment.duration(ms).asMilliseconds()).format(`HH:mm`);
              };
              const getDistance = (payments) => {
                const distance =
                  payments.length > 0
                    ? payments
                        .map((p) => p.move.lane.distance_miles)
                        .reduce((total, currentValue) => total + currentValue)
                        .toFixed(0)
                    : 0;
                return `${distance} mi`;
              };
              const getACH = (payments) => {
                if (payments.length > 0 && payments[0].ach_transaction_id !== null)
                  return `#${payments[0].ach_transaction_id}`;
                else return `N/A`;
              };
              const getFavLoc = (payments) => {
                if (payments.length >= 0) {
                  let locations = [];
                  payments.forEach((p) => {
                    if (p.move.lane && p.move.lane.description) {
                      const locs = p.move.lane.description.split(` to `);
                      locations.push(locs[0]);
                      locations.push(locs[1]);
                    } else {
                      locations.push("Consumer Pickup");
                      locations.push("Consumer Delivery");
                    }
                  });

                  let mapping = {};
                  let count = 0;
                  let mode;
                  locations.forEach((l, i) => {
                    mapping[i] = (mapping[i] || 0) + 1;
                    if (count < mapping[i]) {
                      count = mapping[i];
                      mode = l;
                    }
                  });

                  return mode;
                } else return `N/A`;
              };
              const getFavLane = (payments) => {
                if (payments.length >= 0) {
                  let lanes = [];
                  payments.forEach((p) => {
                    lanes.push(
                      p.move.lane && p.move.lane.description
                        ? p.move.lane.description
                        : "Consumer Pickup to Consumer Delivery"
                    );
                  });

                  let mapping = {};
                  let count = 0;
                  let mode;
                  lanes.forEach((l, i) => {
                    mapping[i] = (mapping[i] || 0) + 1;
                    if (count < mapping[i]) {
                      count = mapping[i];
                      mode = l;
                    }
                  });

                  return mode;
                } else return `N/A`;
              };

              var driver = {};

              driver.id = driverId;
              driver.name = driverName;
              driver.pic = driverPic || null;
              driver.initials = getInitials(driverName);

              driver.twPayments = getPayments(data.appayments, moment().startOf(`week`), moment().endOf(`week`));
              driver.twPay = getPay(driver.twPayments);
              driver.twMoves = getMoves(driver.twPayments);
              driver.twTime = getTime(driver.twPayments);
              driver.twDistance = getDistance(driver.twPayments);

              driver.lwPayments = getPayments(
                data.appayments,
                moment().startOf(`week`).subtract(7, `days`),
                moment().endOf(`week`).subtract(7, `days`)
              );
              driver.lwPay = getPay(driver.lwPayments);
              driver.lwMoves = getMoves(driver.lwPayments);
              driver.lwTime = getTime(driver.lwPayments);
              driver.lwDistance = getDistance(driver.lwPayments);
              driver.ach = getACH(driver.lwPayments);

              driver.ytdPayments = data.appayments;
              driver.ytdPay = getPay(data.appayments);
              driver.ytdMoves = getMoves(data.appayments);
              driver.ytdTime = getTime(data.appayments);
              driver.ytdDistance = getDistance(data.appayments);
              driver.ytdLoc = getFavLoc(data.appayments);
              driver.ytdLane1 = data.appayments.length > 0 ? getFavLane(data.appayments).split(` to `)[0] : "";
              driver.ytdLane2 = data.appayments.length > 0 ? getFavLane(data.appayments).split(` to `)[1] : "";

              log && console.log(`Driver Info:`, driver);
              log && console.log(`Driver Info Time: ${Date.now() - timer} ms`);

              return <DashboardContent driver={driver} />;
            }
          }}
        </Query>
      )}
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: "-1",
    position: "absolute",
    width: "100%",
    height: "416px",
    backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
    [theme.breakpoints.down("sm")]: {
      height: "368px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "312px",
    },
  },
}));

////////// GRAPHQL //////////
const GET_DASHBOARD_INFO = gql`
  query get_dashboard_info {
    appayments(where: { amount: { _gt: 0 } }, order_by: { id: desc }) {
      id
      driver_id
      status
      amount
      ach_transaction_id
      move {
        consumer_pickup
        consumer_name
        consumer_phone
        consumer_at_pickup
        return_ride_id
        id
        active
        status
        driver_name
        customer {
          id
          name
        }
        pickup_time
        delivery_time
        class
        vehicle_color
        vehicle_make
        vehicle_model
        vehicle_year
        lane {
          id
          duration_sec
          distance_miles
          description
          average_drive_speed_mph
          dealer_base_rate_type
          driver_base_pay
          dealer_stranded_rate_type
          driver_return_pay
          pickup {
            id
            name
            type
            address
          }
          delivery {
            id
            name
            type
            address
          }
        }
      }
    }
  }
`;
