import React, { useState, useContext } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import GlobalContext from "../../GlobalContext";

import {
  makeStyles,
  Container,
  Grid,
  Typography,
  IconButton,
  Icon,
} from "@material-ui/core";
import Divide from "../reusable/Divide";

import gql from "graphql-tag";
import { Subscription } from "react-apollo";

import Loading from "../utils/Loading";

import MoveStatusTracker from "../reusable/MoveStatusTracker";
import MoveDetailsInfo from "./moveDetails/MoveDetailsInfo";
import MoveDetailsLane from "./moveDetails/MoveDetailsLane";
import MoveDetailsLocation from "./moveDetails/MoveDetailsLocation";
import MoveDetailsAP from "./moveDetails/MoveDetailsAP";

const log = false;

////////// COMPONENT //////////
function MoveDetails(props) {
  const ctx = useContext(GlobalContext);
  ctx.handlePage();
  const cls = useStyles();

  const moveId = props.match.params.id;

  const [tookan, setTookan] = useState({
    loading: true,
    pickup: {},
    delivery: {},
  });

  const GET_TOOKAN_DATA = async (api_key, job_id) =>
    axios.post(
      `https://${process.env.REACT_APP_TKN_SD}.socialautotransport.com/v2/get_task_details`,
      { api_key: api_key, job_id: job_id }
    );
  const GET_TOOKAN_DRIVERS = async (api_key) =>
    axios.post(
      `https://${process.env.REACT_APP_TKN_SD}.socialautotransport.com/v2/get_available_agents`,
      { api_key: api_key }
    );

  const getTookanData = async (move) => {
    log && console.log(`Getting data from tookan...`);

    let pickupJobId = move ? move.pickup_stop_id : null;
    let deliveryJobId = move ? move.delivery_stop_id : null;
    let pickupData = { images: [], signature: null, name: null };
    let deliveryData = { images: [], signature: null, name: null };
    let pickupImage = null;
    let deliveryImage = null;

    let drivers = [];
    let driver = null;

    // Tookan PICKUP images and signature
    if (pickupJobId) {
      await GET_TOOKAN_DATA(
        ctx.userAuth[`https://api_keys.io/jwt/claims`][
          `TookanKey`
        ],
        pickupJobId
      )
        .then((res) => {
          // log && console.log(`TOOKAN Pickup Response:`, res);
          if (res.status === 200 && res.data.status === 200) {
            let tookanData = res.data.data[0].task_history.map(
              (item) => item.description
            );
            tookanData = tookanData.filter((item) => {
              if (item.includes(`task_images`)) return true;
              else if (item.includes(`acknowledgement_images`)) return true;
              else if (item.includes(`SignerName`)) return true;
              else return false;
            });

            if (tookanData && tookanData.length > 0) {
              let driverFront = tookanData.filter((obj) =>
                obj.includes(`Driver_Front`)
              );
              if (driverFront && driverFront.length > 0)
                pickupImage = JSON.parse(driverFront[0]).fleet_data;

              let extractedData = tookanData.map((obj) => {
                if (obj.includes(`fleet_data`))
                  return JSON.parse(obj).fleet_data;
                else return obj;
              });

              if (extractedData && extractedData.length > 0) {
                extractedData = extractedData.sort((a, b) => {
                  if (
                    !a.includes(`task_images`) &&
                    !a.includes(`acknowledgement_images`)
                  )
                    return 1;
                  if (
                    !b.includes(`task_images`) &&
                    !b.includes(`acknowledgement_images`)
                  )
                    return -1;
                  else if (a.includes(`acknowledgement_images`)) return 1;
                  else if (b.includes(`acknowledgement_images`)) return -1;
                  else return 0;
                });

                for (let i = 0; i < extractedData.length; i++) {
                  if (
                    extractedData[i].includes(`task_images`) ||
                    extractedData[i].includes(`acknowledgement_images`)
                  )
                    pickupData.images.push(extractedData[i]);
                  if (extractedData[i].includes(`acknowledgement_images`))
                    pickupData.signature = extractedData[i];
                  if (
                    !extractedData[i].includes(`task_images`) &&
                    !extractedData[i].includes(`acknowledgement_images`)
                  )
                    pickupData.name = extractedData[i];
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log(`Failed to retrieve Tookan PICKUP data:`, err);
          setTookan({ ...tookan, loading: false });
        });
    }

    // Tookan DELIVERY images and signature
    if (deliveryJobId) {
      await GET_TOOKAN_DATA(
        ctx.userAuth[`https://api_keys.io/jwt/claims`][
          `TookanKey`
        ],
        deliveryJobId
      )
        .then((res) => {
          // log && console.log(`TOOKAN Delivery Response:`, res);
          if (res.status === 200 && res.data.status === 200) {
            let tookanData = res.data.data[0].task_history.map(
              (item) => item.description
            );
            tookanData = tookanData.filter((item) => {
              if (item.includes(`task_images`)) return true;
              else if (item.includes(`acknowledgement_images`)) return true;
              else if (item.includes(`SignerName`)) return true;
              else return false;
            });

            if (tookanData && tookanData.length > 0) {
              let driverFront = tookanData.filter((obj) =>
                obj.includes(`Driver_Front`)
              );
              if (driverFront && driverFront.length > 0)
                deliveryImage = JSON.parse(driverFront[0]).fleet_data;

              let extractedData = tookanData.map((obj) => {
                if (obj.includes(`fleet_data`))
                  return JSON.parse(obj).fleet_data;
                else return obj;
              });

              if (extractedData && extractedData.length > 0) {
                extractedData = extractedData.sort((a, b) => {
                  if (
                    !a.includes(`task_images`) &&
                    !a.includes(`acknowledgement_images`)
                  )
                    return 1;
                  if (
                    !b.includes(`task_images`) &&
                    !b.includes(`acknowledgement_images`)
                  )
                    return -1;
                  else if (a.includes(`acknowledgement_images`)) return 1;
                  else if (b.includes(`acknowledgement_images`)) return -1;
                  else return 0;
                });

                for (let i = 0; i < extractedData.length; i++) {
                  if (
                    extractedData[i].includes(`task_images`) ||
                    extractedData[i].includes(`acknowledgement_images`)
                  )
                    deliveryData.images.push(extractedData[i]);
                  if (extractedData[i].includes(`acknowledgement_images`))
                    deliveryData.signature = extractedData[i];
                  if (
                    !extractedData[i].includes(`task_images`) &&
                    !extractedData[i].includes(`acknowledgement_images`)
                  )
                    deliveryData.name = extractedData[i];
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log(`Error retrieving Tookan DELIVERY data:`, err);
          setTookan({ ...tookan, loading: false });
        });
    }

    // Tookan DRIVER data
    await GET_TOOKAN_DRIVERS(
      ctx.userAuth[`https://api_keys.io/jwt/claims`][`TookanKey`]
    )
      .then((res) => {
        // log && console.log(`TOOKAN Drivers Response:`, res);
        if (
          res.status === 200 &&
          res.data.status === 200 &&
          res.data.data.length > 0
        ) {
          let tookanDrivers = res.data.data;
          let tookanDriver = res.data.data.filter(
            (driver) => driver.fleet_id === move.driver_id
          );
          if (tookanDrivers.length > 0) drivers = tookanDrivers[0];
          if (tookanDriver.length > 0) driver = tookanDriver[0];
        }
      })
      .catch((err) => {
        console.log(`Failed to retrieve Tookan drivers:`, err);
        setTookan({ ...tookan, loading: false });
      });

    // Set Tookan state object
    setTookan({
      loading: false,
      pickup: pickupData,
      pickupImage: pickupImage,
      delivery: deliveryData,
      deliveryImage: deliveryImage,
      drivers: drivers,
      driver: driver,
    });
  };

  const fetchTookan = (move) => {
    getTookanData(move);
    if (
      move.status &&
      move.cancel_status === null &&
      move.status !== `delivery successful` &&
      (move.status.includes(`pickup`) || move.status.includes(`delivery`))
    ) {
      setInterval(() => getTookanData(move), 60000);
    }
  };

  const goBack = () => {
    props.history.goBack();
  };
  const goToLaneDetails = (laneId, move) => {
    if (
      (move.consumer_phone || move.consumer_name) &&
      ["delivery successful", "canceled", "cancelled"].indexOf(move.status) >= 0
    )
      return;
    props.history.push(`/lanes/${laneId}`);
  };
  const goToLocationDetails = (locId, move) => {
    if (
      (move.consumer_phone || move.consumer_name) &&
      ["delivery successful", "canceled", "cancelled"].indexOf(move.status) >= 0
    )
      return;
    props.history.push(`/locations/${locId}`);
  };

  return (
    <>
      <div className={cls.root}>
        <Container maxWidth="lg">
          <IconButton
            style={{ marginRight: ctx.theme.spacing(1) }}
            className={cls.iconBtn}
            onClick={() => goBack()}
          >
            <Icon>arrow_back</Icon>
          </IconButton>
          <Typography className={cls.head}>Move Details</Typography>

          <Subscription subscription={GET_MOVE} variables={{ moveId: moveId }}>
            {({ loading, error, data }) => {
              if (loading) return <Loading fixed />;
              if (error) {
                console.log(`Failed to retrieve move:`, error);
                return (
                  <div className={cls.notFound}>
                    <Typography className={cls.notFoundTxt}>
                      ERROR FINDING MOVE RECORD
                    </Typography>
                  </div>
                );
              }
              if (data && data.moves && data.moves.length > 0) {
                const move = data.moves[0];
                log && console.log(`Move Details:`, move);

                // Set actions
                const laneActions = [
                  {
                    label: `View Lane Details`,
                    handler: () => goToLaneDetails(move.lane.id, move),
                  },
                  {
                    label: `View Pickup Details`,
                    handler: () => {
                      try {
                        goToLocationDetails(move.lane.pickup.id, move);
                      } catch (err) {
                        return;
                      }
                    },
                  },
                  {
                    label: `View Delivery Details`,
                    handler: () => {
                      try {
                        goToLocationDetails(move.lane.delivery.id, move);
                      } catch (err) {
                        return;
                      }
                    },
                  },
                ];

                // Get images and driver data from Tookan (Set interval to fetch)
                if (tookan.loading) {
                  fetchTookan(move);
                }

                return (
                  <>
                    <div
                      style={{ width: "100%", height: ctx.theme.spacing(3) }}
                    />
                    <MoveStatusTracker move={move} size="large" />
                    <div
                      style={{ width: "100%", height: ctx.theme.spacing(3) }}
                    />
                    <MoveDetailsInfo move={move} tookan={tookan} />

                    <Divide
                      spacer
                      tip="View the lane this move is associated with."
                      actions={laneActions}
                    >
                      Lane
                    </Divide>
                    <MoveDetailsLane move={move} />
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                        <MoveDetailsLocation
                          type="pickup"
                          move={move}
                          tookan={tookan.pickup}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <MoveDetailsLocation
                          type="delivery"
                          move={move}
                          tookan={tookan.delivery}
                        />
                      </Grid>
                    </Grid>
                    <Divide
                      spacer
                      tip="View what the driver is being payed for this move."
                    >
                      Driver Pay
                    </Divide>
                    <MoveDetailsAP move={move} />
                  </>
                );
              } else
                return (
                  <div className={cls.notFound}>
                    <Typography className={cls.notFoundTxt}>
                      NO MOVE RECORD FOUND
                    </Typography>
                  </div>
                );
            }}
          </Subscription>
        </Container>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    position: "relative",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  iconBtn: {
    verticalAlign: "top",
    display: "inline-block",
    marginTop: "-12px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-14px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "-16px",
    },
  },
  head: {
    verticalAlign: "top",
    display: "inline-block",
    lineHeight: 1,
    fontSize: "24px",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "21px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  notFound: {
    padding: theme.spacing(4),
    border: "1px solid #ddd",
    borderRadius: "8px",
    marginTop: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    background: "#fff",
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: "center",
    fontSize: "21px",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
}));

////////// GRAPHQL //////////
const GET_MOVE = gql`
  subscription get_moves($moveId: bigint!) {
    moves(where: { id: { _eq: $moveId }, active: { _eq: 1 } }) {
      id
      trip_id
      plan_id
      tookan_relationship_id
      status
      cancel_status
      class
      move_type
      ride_type
      consumer_pickup
      consumer_name
      consumer_phone
      consumer_at_pickup
      return_ride_id
      driver_id
      driver_name
      customer {
        id
        name
      }
      pickup_stop_id
      delivery_stop_id
      lane {
        id
        duration_sec
        distance_miles
        description
        average_drive_speed_mph
        dealer_base_rate_type
        driver_base_pay
        dealer_stranded_rate_type
        driver_return_pay
        pickup {
          id
          name
          type
          address
        }
        delivery {
          id
          name
          type
          address
        }
      }
      ready_by
      deliver_by
      pickup_time
      pickup_started
      pickup_arrived
      pickup_successful
      delivery_time
      delivery_started
      delivery_arrived
      delivery_successful
      reference_num
      manual_flag
      vehicle_stock
      vehicle_vin
      vehicle_make
      vehicle_model
      vehicle_year
      vehicle_color
      vehicle_image
      vehicle_odometer
      move_details
      appayments {
        id
        status
        amount
        notes
        type
        move {
          id
          move_type
        }
        accessorial {
          id
          code
          notes
        }
      }
      accessorials {
        id
        code
        status
        notes
        ap_amount
      }
    }
  }
`;

////////// EXPORT //////////
export default withRouter(MoveDetails);
