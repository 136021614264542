import React, { Component } from "react";
import GlobalContext from "../../GlobalContext";
import { withStyles } from "@material-ui/core/styles";
import { Button, Typography, Container } from "@material-ui/core";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  root: {
    display: "flex",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
});

class LoginPage extends Component {
  render() {
    return (
      <div className={this.props.classes.root}>
        <Container maxWidth="lg">
          <Typography component="h4" variant="h5" gutterBottom>
            Please login to use this site.
            <br />
            <br />
            <Button
              component={Link}
              color="primary"
              to="/"
              variant="contained"
              onClick={() => this.context.auth0.loginWithRedirect()}
            >
              Login
            </Button>
          </Typography>
        </Container>
      </div>
    );
  }
}
LoginPage.contextType = GlobalContext;
export default withStyles(styles)(LoginPage);
