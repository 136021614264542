import React, { useState, useContext } from 'react';
import axios from 'axios';
import GlobalContext from '../../GlobalContext';
import { Container, Grid, Typography, makeStyles, TextField, Button, InputAdornment, Icon } from '@material-ui/core';

import PageRoot from '../reusable/PageRoot';
import Divide from '../reusable/Divide';

import StaticAlert from '../utils/StaticAlert';

let log = false;

////////// COMPONENT //////////
export default function Contact() {
  const { handlePage } = useContext(GlobalContext);
  handlePage();
  const cls = useStyles();

  const [state, setState] = useState({
    name: '',
    email: '',
    msg: ''
  });
  const [emailStatus, setEmailStatus] = useState('unsent');

  const handleEmail = async () => {
    try {
      await axios({
        method: 'POST',
        url: '/.netlify/functions/handleContactFormEmail',
        data: state
      })
        .then(res => {
          log && console.log("Response of handleEmail():", res);
          if (res.status === 200) setEmailStatus('success');
          else setEmailStatus('failed');
        })
        .catch(err => {
          console.log("Error in handleEmail():", err);
          setEmailStatus('failed');
        })
    } catch (err) {
      console.log("Error in handleEmail():", err);
      setEmailStatus('failed');
    }
  }

  const handleChange = event => setState({ ...state, [event.target.name]: event.target.value });
  const formValid = () => {
    if (!state.name || state.name.trim().length < 1) return false;
    if (!state.email || state.email.trim().length < 1) return false;
    if (!state.msg || state.msg.trim().length < 1) return false;
    else return true;
  }
  const emailError = () => {
    if (!state.email || state.email.trim().length < 1) return false;
    if (!(/\S+@\S+\.\S+/).test(state.email)) return true;
    else return false;
  }

  return (
    <PageRoot>
      <Container maxWidth="md">

        <Divide>Call</Divide>
        <div className={cls.paper}>
          <Typography className={cls.subhead + " " + cls.center}>Tap to call your dispatcher</Typography>
          <a href={`tel:800-913-7674`} className={cls.link} >
            <Typography variant='h6' className={cls.center}>(800) 913-7674</Typography>
          </a>
        </div>

        <Divide spacer>Email</Divide>
        <div className={cls.paper}>
          <Typography className={cls.subhead + " " + cls.center}>Fill out the form to email your&nbsp;dispatcher</Typography>
          {(emailStatus === 'unsent') ? (
            <form className={cls.form} noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    id="name-input"
                    label="Your Name"
                    name="name"
                    placeholder="Enter your first & last name..."
                    margin="dense"
                    variant="outlined"
                    value={state.name}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment style={{ verticalAlign: "top" }} position="start">
                          <Icon color="disabled" fontSize="small">local_offer</Icon>
                        </InputAdornment>
                      )
                    }}
                    className={cls.input}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    id="email-input"
                    label="Your Email"
                    name="email"
                    placeholder="Enter your email address..."
                    margin="dense"
                    variant="outlined"
                    value={state.email}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment style={{ verticalAlign: "top" }} position="start">
                          <Icon color="disabled" fontSize="small">mail</Icon>
                        </InputAdornment>
                      )
                    }}
                    error={emailError()}
                    className={cls.input}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows="10"
                    id="message-input"
                    label="Your Message"
                    name="msg"
                    placeholder="Please describe your issue here..."
                    margin="dense"
                    variant="outlined"
                    value={state.msg}
                    onChange={handleChange}
                    className={cls.input}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    disabled={!formValid()}
                    variant='contained'
                    size='large'
                    color='primary'
                    onClick={() => handleEmail()}
                    className={cls.btn}
                  >
                    Send
                  </Button>
                </Grid>
              </Grid>
            </form>
          ) : (emailStatus === 'success') ? (
            <StaticAlert type='success' message='Your message has been sent. A dispatcher will respond shortly.' />
          ) : (
                <StaticAlert type='error' message='Your message could not be sent. Please try again later or call our dispatch line for immediate assistance.' />
              )
          }
        </div>

      </Container>
    </PageRoot>
  )
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    border: theme.border,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
  },
  center: {
    textAlign: 'center',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  subhead: {
    color: theme.palette.text.primary,
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
  },
  form: {
    marginTop: theme.spacing(2),
  },
  input: {
    margin: 0,
  },
  btn: {
    boxShadow: 'none',
  },
}));