import React from 'react';
import { withRouter } from 'react-router';
import { makeStyles, Grid, Typography, Tooltip } from '@material-ui/core';
import helpers from '../../utils/helpers';

const capFirst = (str) => { if (str) return str.charAt(0).toUpperCase() + str.slice(1); }

////////// COMPONENT //////////
function MoveDetailsLane(props) {
  const cls = useStyles();

  const { move } = props;
  const { lane } = move;

  let ln = {};

  ln.totalTime = Number(lane.duration_sec) / 60;
  ln.distance = Number(lane.distance_miles);
  ln.averageSpeed = Number(lane.average_drive_speed_mph);

  ln.baseRateType = capFirst(lane.dealer_base_rate_type);
  ln.basePay = Number(lane.driver_base_pay);

  ln.strandedRateType = capFirst(lane.dealer_stranded_rate_type);
  ln.strandedPay = Number(lane.driver_base_pay) + Number(lane.driver_return_pay);

  return (<>
    <div className={cls.paper}>
      <Grid container spacing={0} alignItems="flex-start" wrap="nowrap">
        <Grid item xs>
          <Tooltip placement="top-start" title="Lane ID">
            {
              (move.consumer_name || move.consumer_phone) && ['delivery successful', 'canceled', 'cancelled'].indexOf(move.status) >= 0 ?
                <Typography className={cls.headTxtL}>Lane&nbsp;Info</Typography>
                :
                <Typography className={cls.headTxtL}>Lane&nbsp;#{lane.id}</Typography>
            }
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip placement="top-end" title="Pickup to Delivery">
            <Typography className={cls.headTxtR}>{helpers.handleLaneDescription(move)}</Typography>
          </Tooltip>
        </Grid>
      </Grid>
      <div className={cls.break} />
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <Typography className={cls.subheadTxt}>Route Info</Typography>
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Total&nbsp;Time:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{ln.totalTime.toFixed(1)}&nbsp;min</Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Distance:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{ln.distance.toFixed(1)}&nbsp;mi</Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Average&nbsp;Speed:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{ln.averageSpeed.toFixed(1)}&nbsp;mph</Typography>
          </div>
        </Grid>

        <div className={cls.hiddenLineBreak} />

        <Grid item sm={4} xs={12}>
          <Typography className={cls.subheadTxt}>Base Standards</Typography>
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Base&nbsp;Rate&nbsp;Type:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{ln.baseRateType}</Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Base&nbsp;Pay:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>${ln.basePay.toFixed(2)}</Typography>
          </div>
        </Grid>

        <div className={cls.hiddenBreak} />

        <Grid item sm={4} xs={12}>
          <Typography className={cls.subheadTxt}>Stranded Standards</Typography>
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Stranded&nbsp;Rate&nbsp;Type:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>{ln.strandedRateType}</Typography>
          </div>
          <div className={cls.lineBreak} />
          <div className={cls.listItem}>
            <Typography className={cls.keyTxt}>Stranded&nbsp;Pay:&nbsp;&nbsp;</Typography>
            <Typography className={cls.valTxt}>${ln.strandedPay.toFixed(2)}</Typography>
          </div>
        </Grid>
      </Grid>
    </div>
    <div className={cls.break} />
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    border: '1px solid #ddd',
    borderRadius: '8px',
    background: '#fff',
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    cursor: 'default',
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
    cursor: 'default',
  },
  subheadTxt: {
    marginBottom: theme.spacing(1.5),
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#eee',
  },
  hiddenBreak: {
    display: 'none',
    width: '100%',
    height: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  midBreak: {
    width: '100%',
    height: theme.spacing(1.5),
  },
}));

////////// EXPORT //////////
export default withRouter(MoveDetailsLane);