import React, { useState, useEffect, useRef } from "react";
import GlobalContext from "./GlobalContext";

import ApolloClient from "apollo-client";
import { ApolloProvider } from "@apollo/react-hooks";
import { split } from "apollo-link";
import { setContext } from "apollo-link-context";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { InMemoryCache } from "apollo-cache-inmemory";

import { withWidth, CssBaseline } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import Nav from "./components/Nav";
import Content from "./components/Content";
import Routes from "./Routes";
import NotificationPopup from "./components/reusable/NotificationPopup";
import * as Sentry from "@sentry/react";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/utils/Loading";

let log = false; // Toggle developer console logging

////////// STYLES //////////
const theme = createMuiTheme({
  palette: {
    primary: {
      verylight: `#80c8ff`,
      light: `#50afff`,
      main: `#2096f8`,
      dark: `#1268b0`,
      verydark: `#104072`,
      contrastText: `#fff`,
    },
    secondary: {
      verylight: `#c0c8d0`,
      light: `#9098a0`,
      main: `#505860`,
      dark: `#202830`,
      verydark: `#101820`,
      contrastText: `#fff`,
    },
    error: { verylight: `#ffdddd`, light: `#e57373`, main: `#f44336`, dark: `#d32f2f`, contrastText: `#fff` },
    warning: { verylight: `#ffffcc`, light: `#ffb74d`, main: `#ff9800`, dark: `#f57c00`, contrastText: `#fff` },
    info: { light: `#64b5f6`, main: `#2196f3`, dark: `#1976d2`, contrastText: `#fff` },
    success: { light: `#81c784`, main: `#4caf50`, dark: `#388e3c`, contrastText: `#fff` },

    text: {
      primary: `rgba(32, 40, 48, 0.8)`,
      secondary: `rgba(32, 40, 48, 0.5)`,
      disabled: `rgba(32, 40, 48, 0.4)`,
      hint: `rgba(32, 40, 48, 0.4)`,
      contrast: `#fff`,
    },

    softDivider: `rgba(32, 40, 48, 0.10)`,
    divider: `rgba(32, 40, 48, 0.15)`,
    hardDivider: `rgba(32, 40, 48, 0.2)`,

    action: {
      active: `rgba(32, 40, 48, 0.55)`,
      hover: `rgba(32, 40, 48, 0.05)`,
      selected: `rgba(32, 40, 48, 0.1)`,
      disabled: `rgba(32, 40, 48, 0.3)`,
      disabledBackground: `rgba(32, 40, 48, 0.15)`,
      focus: `rgba(32, 40, 48, 0.15)`,
    },

    background: {
      paper: `#fff`,
      default: `#f8f8f8`,
      light: `#f5f5f5`,
      main: `#f2f2f2`,
      dark: `#efefef`,
    },

    common: {
      black: `#000`,
      white: `#fff`,
    },
  },
  shape: {
    borderRadius: `4px`,
  },
  border: `1px solid rgba(32, 40, 48, 0.15)`,
  shadow: `0px 3px 8px #00000040`,
  typography: { useNextVariants: true },
});

////////// COMPONENT //////////
function App(props) {
  const { width } = props;

  const auth0 = useAuth0();

  const [page, setPage] = useState(``);
  const [apolloClient, setApolloClient] = useState(null);
  const [redirectPath, setRedirectPath] = useState(null);
  const [userAuth, setUserAuth] = useState(null);
  const [notificationShow, setNotificationShow] = useState(false);
  const [notificationVariant, setNotificationVariant] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [apolloInitialized, setApolloInitialized] = useState(false);

  function handleNotifications(show, variant = notificationVariant, message = notificationMessage) {
    setNotificationShow(show);
    setNotificationVariant(variant);
    setNotificationMessage(message);
  }

  const handlePage = () => {
    log && console.log(window.location.pathname);
    if (window.location.pathname === `/`) setPage(`My\xa0Dashboard`);
    else if (window.location.pathname === `/login`) setPage(`Sign\xa0In`);
    else if (window.location.pathname === `/pay`) setPage(`My\xa0Pay`);
    else if (window.location.pathname === `/schedule`) setPage(`My\xa0Schedule`);
    else if (window.location.pathname.includes(`/locations`)) setPage(`Locations`);
    else if (window.location.pathname.includes(`/lanes`)) setPage(`Lanes`);
    else if (window.location.pathname === `/contact`) setPage(`Contact`);
    else if (window.location.pathname === `/faq`) setPage(`FAQ`);
    else if (window.location.pathname.includes(`/moves/`)) setPage(`Move\xa0Details`);
    else setPage(`Page Not Found`);
  };

  useEffect(() => {
    // Set inital page
    handlePage();
    setApolloClient(async () => {
      let token = null;
      if (auth0.isAuthenticated) {
        try {
          let { __raw: JWT } = await auth0.getIdTokenClaims();
          token = JWT;
        } catch (err) {
          console.error("Failed to fetch user JWT:", err);
          token = null;
        }
      }
      // Pass in (token) if using a JWT or other auth token (retrieved after login and redirect)
      if (log) console.log("Setting up the Apollo Client...");
      const authLink = setContext(async (_, { headers }) => {
          if (token) {
            return {
              headers: {
                ...headers,
                authorization: `Bearer ${token}`, // Pass in token if using JWT/auth token
              },
            };
          }
        }),
        wsurl = process.env.REACT_APP_GRAPHQL_WSS_URL, // GraphQL URL endpoint
        httpurl = process.env.REACT_APP_GRAPHQL_URL, // GraphQL websocket endpoint
        wsLink = new WebSocketLink({
          uri: wsurl,
          options: {
            lazy: true,
            reconnect: true,
            timeout: 30000,
            connectionParams: async () => {
              return {
                headers: {
                  Authorization: `Bearer ${token}`, // Pass in token if using JWT/auth token
                },
              };
            },
          },
        }),
        httpLink = new HttpLink({
          uri: httpurl,
        }),
        link = split(
          // split based on operation type
          ({ query }) => {
            const { kind, operation } = getMainDefinition(query);
            return kind === "OperationDefinition" && operation === "subscription";
          },
          wsLink,
          authLink.concat(httpLink)
        ),
        client = new ApolloClient({
          link,
          cache: new InMemoryCache(),
        });
      if (log) {
        console.log("Apollo Client Initialized! ", client);
      }
      setApolloClient(client);
      if (auth0.isAuthenticated) setUserAuth(auth0.user);
      setApolloInitialized(true);
    });
  }, [auth0]);

  function logout() {
    auth0.logout({ returnTo: process.env.REACT_APP_A0_CB_SD });
  }

  const context = {
    page: page,
    handlePage: handlePage,
    redirectPath: redirectPath,
    setRedirectPath: setRedirectPath,
    apolloClient: apolloClient,
    userAuth: userAuth,
    theme: theme,
    width: width,
    notificationShow: notificationShow,
    notificationVariant: notificationVariant,
    notificationMessage: notificationMessage,
    handleNotifications: handleNotifications,
    auth0: auth0,
    logout: logout,
  };

  const buildSentryUserObj = (userProfile) => ({
    email: userProfile.email,
    name: userProfile.name,
    nickname: userProfile.nickname,
    user: userProfile["https://hasura.io/jwt/claims"]["x-hasura-user-id"],
    role: userProfile["https://hasura.io/jwt/claims"]["x-hasura-default-role"],
  });

  if (auth0.isLoading || !apolloInitialized) return <Loading />;
  else if (apolloInitialized) {
    userAuth && Sentry.setContext("user", buildSentryUserObj(userAuth));
    return (
      <MuiThemeProvider theme={theme}>
        <ApolloProvider client={apolloClient}>
          <GlobalContext.Provider value={context}>
            <NotificationPopup
              show={notificationShow}
              variant={notificationVariant ? notificationVariant : "info"}
              message={notificationMessage}
              handleClose={handleNotifications}
            />
            <CssBaseline />
            <Nav />
            <Content>
              <Routes />
            </Content>
          </GlobalContext.Provider>
        </ApolloProvider>
      </MuiThemeProvider>
    );
  } else return null;
}

export default withWidth()(App);
