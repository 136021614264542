import React from 'react';
import { withRouter } from 'react-router';
import { makeStyles, Container, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Divide from '../../reusable/Divide';

////////// COMPONENT //////////
function LocationsSearch(props) {
  const cls = useStyles();

  const options = props.locations.map(option => {
    const firstLetter = option.name[0].toUpperCase();
    return { firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter, ...option }
  });

  const handleChange = (event, value) => {
    if (value) props.history.push(`/locations/${value.id}`);
  }

  return (
    <div className={cls.backdrop}>
      <Container maxWidth="md">
        <Divide color="white" smallSpacer>Search</Divide>
        <div className={cls.hold}>
          <Autocomplete
            id="locations-autosuggest"
            options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
            groupBy={option => option.firstLetter}
            getOptionLabel={option => option.name}
            getOptionSelected={option => option}
            className={cls.autocomplete}
            renderInput={params => (<TextField {...params} label="" placeholder="Search for a location..." variant="outlined" margin="dense" style={{ margin: 0 }} fullWidth />)}
            onChange={handleChange}
            disablePortal={true}
          />
        </div>
      </Container>
    </div>
  )
}

////////// EXPORT //////////
export default withRouter(LocationsSearch);

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  backdrop: {
    position: 'relative',
    width: '100%',
    paddingBottom: theme.spacing(2),
    backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
  },
  hold: {
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
  },
}));