import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button, Icon } from '@material-ui/core';

export default function Printable({
    trigger = <Button size="small" style={{ margin: "6px" }} variant="contained" color='primary' startIcon={<Icon fontSize='small'>print</Icon>}>Print</Button>,
    hide = false,
    pageStyle = '',
    landscape = false,
    children
}) {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: landscape ? '@page { size: A4 landscape; }' : pageStyle
    });

    const ComponentClickWrapper = ({ trigger }) => {
        return <div onClick={handlePrint} style={{ display: 'inline-block' }}>{trigger}</div>;
    };

    return (<>
        {trigger &&
            <ComponentClickWrapper trigger={trigger} />
        }
        <br />
        <div id='print-component' style={{ display: hide ? "none" : "inline" }}>
            <PrintComponent ref={componentRef}>
                {children}
            </PrintComponent>
        </div>
    </>);
}

//Need to use a class wrapper here because ReactToPrint requires a class to work
class PrintComponent extends React.Component {
    render() {
        return (<>
            {this.props.children}
        </>);
    }
}