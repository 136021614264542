import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, Box, Typography, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Chip, Icon, LinearProgress, Button } from '@material-ui/core';
import moment from 'moment'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import PayPeriodDetailTableHeader from './PayPeriodDetailTableHeader'
import Printable from '../../reusable/Printable'
import { ExportToCsv } from 'export-to-csv'
import fragments from '../../utils/graphql/fragments';
import { useHistory } from 'react-router';

export default function PayPeriodDetailTable({ payPeriod }) {
  const history = useHistory();
  const cls = useStyles();

  const generateCSV = appayments => {
    const createCsvRow = (appayment) => {
      return {
        MOVE_ID: appayment.move.id,
        DRIVER_NAME: appayment.move.driver_name,
        PICKUP_TIME: moment(appayment.move.pickup_time).format("MM/DD/YYYY HH:mm"),
        TYPE: appayment.type === 'move pay' ? `${appayment.type} (${appayment.move.move_type})` : (appayment.type === 'accessorial') ? `${appayment.type} (${appayment.accessorial.code})` : appayment.notes,
        DESCRIPTION: appayment.type === 'move pay' ? appayment.move.lane.description : (appayment.type === 'accessorial') ? appayment.accessorial.notes : appayment.notes,
        DISTANCE: appayment.type === 'move pay' ? appayment.move.lane.distance_miles + ' mi' : '',
        STATUS: appayment.status,
        AMOUNT: appayment.amount.toFixed(2),
      }
    }
    const csvRows = appayments.map(appayment => createCsvRow(appayment));
    const csvOptions = {
      filename: `${payPeriod.driver_id ? payPeriod.driver_name.replace(/ /g, "_") : `All`}_Driver_Pay_from_${moment(payPeriod.pickup_time_start).format('MM/DD/YYYY HH:mm')}_to_${moment(payPeriod.pickup_time_end).format('MM/DD/YYYY HH:mm')}`,
      showTitle: true,
      title: `${payPeriod.driver_id ? payPeriod.driver_name : `All`} Driver Pay from ${moment(payPeriod.pickup_time_start).format('MM/DD/YYYY HH:mm')} to ${moment(payPeriod.pickup_time_end).format('MM/DD/YYYY HH:mm')}`,
      useKeysAsHeaders: true,
    }

    // Create and generate the CSV
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvRows);
  }

  return (
    <Query fetchPolicy="no-cache" query={GET_APPAYMENTS} variables={{ driverId: payPeriod.driver_id, start: payPeriod.pickup_time_start, end: payPeriod.pickup_time_end }}>
      {({ loading, error, data, refetch }) => {
        if (loading) {
          return <LinearProgress />
        }
        if (error) {
          console.log(`Failed to retrieve appayments for driver pay priod ${payPeriod.driver_id}-${payPeriod.pay_period}:`, error);
          return (
            <div className={cls.notFound}>
              <Typography className={cls.notFoundTxt}>ERROR FINDING AP RECORDS</Typography>
            </div>
          )
        }
        return (
          <Box style={{ backgroundColor: 'whiteSmoke' }} >
            {data && data.appayments && data.appayments.length == 0 &&
              <>
                <div className={cls.notFound}>
                  <Typography className={cls.notFoundTxt}>No records found</Typography>
                </div>
              </>
            }
            {data && data.appayments && data.appayments.length > 0 &&
              <>
                <Button
                  size="small"
                  style={{ margin: "6px" }}
                  variant="outlined"
                  color='secondary'
                  startIcon={<Icon fontSize='small'>insert_chart</Icon>}
                  onClick={() => { generateCSV(data.appayments) }}>
                  Generate CSV
                </Button>
                <Printable>
                  <Box padding={2} style={{ display: 'inline-block' }}>
                    <PayPeriodDetailTableHeader appayments={data.appayments} payPeriod={payPeriod} hideLogoLine />
                    <br />
                    <TableContainer>
                      <Table style={{ tableLayout: 'auto' }}>
                        {/* <TableHead>
                          <TableRow>
                            <TableCell align="left" className={cls.col}>Type</TableCell>
                            <TableCell align="left" className={cls.col}>Date</TableCell>
                            <TableCell align="left" className={cls.col}>Distance</TableCell>
                            <TableCell align="right" className={cls.col}>Pay</TableCell>
                          </TableRow>
                        </TableHead> */}
                        <TableBody>
                          {
                            (data.appayments.map(appayment => {
                              return (
                                <React.Fragment key={`appayment-${appayment.id}-${appayment.move.id}-row`}>
                                  <TableRow
                                    key={`table-row-${appayment.id}`}
                                    className={cls.clickableRow}
                                    onClick={() => history.push(`/moves/${appayment.move.id}`)}
                                  >
                                    <TableCell align="left"><Chip label={appayment.type === 'move pay' ? appayment.move.move_type : (appayment.type === 'accessorial') ? `adj` : appayment.notes} variant={(appayment.type === 'move pay') ? 'default' : 'outlined'} icon={<Icon fontSize='small'>{appayment.type === 'move pay' ? (appayment.move.move_type === 'drive') ? 'drive_eta' : 'local_taxi' : 'monetization_on'}</Icon>} size="small" color={appayment.type === 'move pay' && appayment.move.move_type === 'drive' ? 'secondary' : 'primary'} /></TableCell>
                                    <TableCell align="left">{moment(appayment.move.pickup_time).format("ddd")}</TableCell>
                                    <TableCell align="left">{appayment.type === 'move pay' ? appayment.move.lane.distance_miles + ' mi' : null}</TableCell>
                                    <TableCell align="right">${appayment.amount.toFixed(2)}</TableCell>
                                  </TableRow>
                                </React.Fragment>
                              )
                            }))
                          }

                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Printable>
              </>}
          </Box>
        )
      }}
    </Query>
  )
}


const useStyles = makeStyles(theme => ({
  notFound: {
    padding: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  clickableRow: {
    "&:hover": {
      background: theme.palette.action.hover,
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  col: {
    width: '25%'
  }
}));

const GET_APPAYMENTS = gql`
  query get_payments_for_driver_pay_detail_table($driverId: bigint, $start: timestamptz, $end: timestamptz) {
    appayments(where: {
      move: {
        active: {_eq: 1},
        pickup_time: {_gte: $start, _lte: $end},
        _or: [{cancel_status: {_neq: "canceled"}}, {cancel_status: {_is_null: true}}]
      },
      active: {_eq: 1},
      driver_id: {_eq: $driverId},
    }, order_by: {move: {pickup_time: desc}}) {
      ...APPayment
      move {
        id
        driver_id
        driver_name
        pickup_time
        move_type
        lane {
          description
          distance_miles
          duration_sec
          tolls
        }
      }
      accessorial {
        ...Accessorials
      }
    }
  }
  ${fragments.appayment}
  ${fragments.accessorials}
`;