import React, { useEffect, useContext } from 'react';
import GlobalContext from '../GlobalContext';
import { makeStyles, AppBar, Typography, Icon, IconButton, Menu, MenuItem } from '@material-ui/core';

import NavMenu from './NavMenu';

////////// COMPONENT //////////
export default function Nav() {
  const { page, logout } = useContext(GlobalContext);
  const cls = useStyles();

  const [renderLogo, setRenderLogo] = React.useState(``);
  const [renderTitle, setRenderTitle] = React.useState(`hideTitle`);

  useEffect(() => {
    setRenderLogo(`showLogo`);
    const timer = setTimeout(() => { setRenderLogo(`hideLogo`); setRenderTitle(`showTitle`); }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const [menu, setMenu] = React.useState(false);
  const [options, setOptions] = React.useState(null);

  const handleMenuClose = () => {
    setMenu(false);
  };
  const handleMenuOpen = () => {
    setMenu(true);
  };

  const handleOptionsOpen = event => {
    setOptions(event.currentTarget);
  }
  const handleOptionsClose = event => {
    setOptions(null);
  }
  const handleAction = (action) => {
    handleOptionsClose();
    action.function();
  }
  const actions = [
    {
      name: `Sign\xa0Out`,
      function: () => { logout() }
    },
  ];

  return (<>
    <div className={cls.nav}>
      <AppBar className={cls.navBar}>
        <div className={cls.flex}>
          <div className={cls.navBar3rd}>
            <div className={cls.navBarLeft}>
              <IconButton className={cls.iconBtn} color="inherit" aria-label="menu" onClick={handleMenuOpen}>
                <Icon className={cls.icon}>menu</Icon>
              </IconButton>
            </div>
          </div>
          <div className={cls.navBar3rd}>
            <img className={cls.logo + " " + renderLogo} alt="sat-logo" src="https://www.socialautotransport.com/wp-content/uploads/2018/05/sat-logo-white@2x.png" />
            <Typography className={cls.title + " " + renderTitle}>{page}</Typography>
          </div>
          <div className={cls.navBar3rd}>
            <div className={cls.navBarRight}>
              <IconButton className={cls.iconBtn} color="inherit" aria-label="options" onClick={handleOptionsOpen}>
                <Icon className={cls.icon}>settings</Icon>
              </IconButton>
            </div>
          </div>
        </div>
      </AppBar>
    </div>
    <Menu keepMounted id="app-options" anchorEl={options} open={Boolean(options)} onClose={handleOptionsClose}>
      {actions.map((action, i) => { return <MenuItem key={i} onClick={() => handleAction(action)}>{action.name}</MenuItem> })}
    </Menu>
    <NavMenu open={menu} handleClose={handleMenuClose} handleOpen={handleMenuOpen} />
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  nav: {
    display: 'block',
    position: 'relative',
    width: '100%',
  },
  navBar: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    minHeight: '56px',
    maxHeight: '56px',
    padding: '0 !important',
    boxShadow: theme.shadow,
  },
  flex: {
    display: 'flex',
    flexWrap: 'nowrap',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
  navBar3rd: {
    position: 'relative',
    width: '100%',
    minHeight: '56px',
    maxHeight: '56px',
  },
  navBarLeft: {
    float: 'left',
    minHeight: '56px',
    maxHeight: '56px',
  },
  navBarRight: {
    float: 'right',
    minHeight: '56px',
    maxHeight: '56px',
  },
  logo: {
    position: 'absolute',
    top: '11px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '160px',
  },
  title: {
    position: 'absolute',
    top: '13px',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    textShadow: '0px 2px 5px #00000032',
  },
  iconBtn: {
    display: 'inline-block',
    maxWidth: '48px',
    maxHeight: '48px',
    marginTop: theme.spacing(0.5),
  },
  icon: {
    textShadow: '0px 2px 5px #00000032',
  },
}));