import React from 'react';
import { Route, Switch } from "react-router-dom";
import PrivateRoute from './components/utils/PrivateRoute';

// Standard Pages
import LoginRedirect from './components/utils/LoginRedirect';
import Dashboard from './components/pages/Dashboard';
import MoveDetails from './components/pages/MoveDetails';
import Location from './components/pages/Location';
import Lane from './components/pages/Lane';
import Contact from './components/pages/Contact';
import Faq from './components/pages/Faq';
import Schedule from './components/pages/Schedule';
import DriverPay from './components/pages/DriverPay2'

export default function Routes() {
  return (
    <Switch>

      <Route exact path="/login" component={LoginRedirect} />

      <PrivateRoute exact path="/" component={Dashboard} />
      <PrivateRoute exact path="/pay" component={DriverPay} />
      <PrivateRoute exact path="/moves/:id/" component={MoveDetails} />
      <PrivateRoute exact path="/schedule" component={Schedule} />
      <Route exact path="/locations" component={Location} />
      <Route exact path="/locations/:id" component={Location} />
      <Route exact path="/lanes" component={Lane} />
      <Route exact path="/lanes/:id" component={Lane} />
      <PrivateRoute exact path="/contact" component={Contact} />
      <PrivateRoute exact path="/faq" component={Faq} />

    </Switch>
  );
}