import React from 'react';
import MUIRichTextEditor from 'mui-rte';
import { withRouter } from 'react-router';
import { makeStyles, Container, Typography, Button } from '@material-ui/core';
import GoogleStaticMapURL from '../../utils/GoogleStaticMapUrl';
import helpers from '../../utils/helpers';

import PageRoot from '../../reusable/PageRoot';
import Divide from '../../reusable/Divide';

////////// COMPONENT //////////
function LocationDetailsContent(props) {
  const cls = useStyles();

  const loc = props.loc;

  const staticMapUrl = (location) => GoogleStaticMapURL.build({
    key: process.env.REACT_APP_GOOGLE_STATIC_MAPS_KEY,
    markers: [
      {
        icon: 'https://s3.ap-south-1.amazonaws.com/social-auto/acknowledgement_images/fkpH1532077213498-faviconsavein32x32.png',
        shadow: true,
        lat: location.latitude,
        lon: location.longitude,
      }
    ],
    center: 'auto',
    zoom: 'auto',
    scale: false,
    size: '720x360',
    maptype: 'roadmap',
    format: 'png',
    visual_refresh: true
  });

  return (<>
    <PageRoot>
      <Container maxWidth="md">
        <Divide>Map</Divide>

        <div className={cls.paper}>
          <img className={cls.map} src={staticMapUrl(loc)} alt={`location-img`} />
          <div className={cls.btnHold}>
            <a href={`https://maps.google.com/?q=${loc.address}`} target="_blank" rel="noopener noreferrer" >
              <Button variant="contained" color="primary" className={cls.btn}>Open in Google</Button>
            </a>
          </div>
        </div>

        <Divide spacer>Location</Divide>

        <div className={cls.paper}>
          <Typography className={cls.subtxt}>{loc.name}</Typography>
          <Typography className={cls.txt}>{helpers.handleCommaWhitespace(loc.address)}</Typography>
          <Typography className={cls.txt}>Customer - {loc.customer.name}</Typography>
        </div>

        {loc.email || loc.phone || loc.notes ? <>
          <Divide spacer>Details</Divide>

          <div className={cls.paper}>
            {loc.email && <>
              <a className={cls.link} href={`mailto:${loc.email}`}>
                <Typography variant="body1">{loc.email}</Typography>
              </a>
            </>}
            {loc.phone && <>
              <a className={cls.link} href={`tel:${loc.phone}`}>
                <Typography variant="body1">{loc.phone}</Typography>
              </a>
            </>}
            {loc.notes && <>
              <MUIRichTextEditor
                value={loc.notes}
                readOnly={true}
                toolbar={false}
              />
            </>}
          </div>
        </> : null
        }
        {/* dispatch contact */}
        <Divide spacer>Call</Divide>
        <div className={cls.paper}>
          <Typography className={cls.subhead}>Tap to call your dispatcher</Typography>
          <a href={`tel:800-913-7674`} className={cls.link} >
            <Typography variant='h6' className={cls.center}>(800) 913-7674</Typography>
          </a>
        </div>
      </Container>
    </PageRoot>
  </>)
}

export default withRouter(LocationDetailsContent);

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    border: theme.border,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
  },
  paperLink: {
    padding: theme.spacing(2),
    border: theme.border,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      background: theme.palette.background.dark,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  map: {
    display: 'block',
    width: '100%',
    maxWidth: '720px',
    border: theme.border,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  subtxt: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: 18,
    fontWeight: 700,
  },
  subhead: {
    color: theme.palette.text.primary,
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
  },
  txt: {
    lineHeight: 1.25,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  link: {
    color: theme.palette.primary.main,
  },
  btnHold: {
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  btn: {
    boxShadow: 'none',
    "&:hover": {
      background: theme.palette.primary.light,
      boxShadow: 'none',
    }
  },
}));