import React from 'react';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import fragments from '../utils/graphql/fragments';

import LaneSearch from './lane/LaneSearch';
import LaneContent from './lane/LaneContent';
import Loading from '../utils/Loading';
import StaticAlert from '../utils/StaticAlert';

////////// COMPONENT //////////
export default function Lanes(props) {
  return (<>
    <Query query={GET_LANES} >
      {({ loading, error, data }) => {
        if (loading) return <Loading />
        if (error) {
          console.log("Failed to load list of lanes:", error);
          return <StaticAlert type="error" message="Failed to load list of lanes." />
        };
        if (data) return <LaneSearch lanes={data.lanes} />
        else return <StaticAlert type="warning" message="List of lanes not found." />
      }}
    </Query>
    {window.location.pathname !== `/lanes` ?
      <Query query={GET_LANE} variables={{ id: props.match.params.id }} >
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) {
            console.log("Failed to load result lane:", error);
            return <StaticAlert type="error" message="Failed to load result lane." />
          }
          if (data.lanes[0]) return <LaneContent lane={data.lanes[0]} />
          else return <StaticAlert type="warning" message="Result lane not found." />
        }}
      </Query > : null
    }
  </>)
}

////////// GRAPHQL //////////
const GET_LANES = gql`
  query {
    lanes(
      order_by: {
        description: asc
      }
      where: {
        active: {
          _eq: 1
        }
        _and: [
          {pickup: {type: {_eq: "customer"}}},
          {delivery: {type: {_eq: "customer"}}}
        ]
      }
    ) {
      id
      description
    }
  }
`;

const GET_LANE = gql`
  query getLane($id: bigint!) {
    lanes(
      where: {
        id: {
          _eq: $id
        }
        _and: [
          {pickup: {type: {_eq: "customer"}}},
          {delivery: {type: {_eq: "customer"}}}
        ]
      }
    ) {
      ...Lane
    }
  }
  ${fragments.lane}
`;